import {
  Alert,
  Box,
  Button,
  HStack,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { FormField } from "~/components/FormField";
import { PasswordInput } from "~/components/FormField/PasswordInput";
import { RouterLink } from "~/components/Link";
import { LoadingOverlay } from "~/components/LoadingOverlay/LoadingOverlay";
import { useAuth } from "~/features/auth/AuthContext";
import {
  useAcceptUserInviteMutation,
  useInvitationTokenQuery,
} from "~/gql/generated";
import { skipQuery } from "~/gql/utils";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import { ampli } from "~/tracking/amplitude";
import { AuthError } from "../components/AuthError";
import { AuthFormContainer } from "../components/AuthFormContainer";
import { AuthPageContainer } from "../components/AuthPageContainer";

export function InvitationRegisterPage() {
  const { token } = useParams<{ token: string }>();
  const urls = useAppUrls();
  const { isLoading, data } = useInvitationTokenQuery(
    skipQuery(
      token && {
        token,
      }
    )
  );
  const [acceptUserInvite] = useAcceptUserInviteMutation();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      username: "",
      password: "",
      confirm: "",
    },
  });
  const { errors, isDirty, isSubmitted } = form.formState;

  const auth = useAuth();

  useDocumentTitle("Sign Up");

  const invitation = data?.invitation;

  useEffect(() => {
    if (!invitation?.email) return;
    form.setValue("username", invitation.email);
  }, [invitation]);

  useEffect(() => {
    // user is logged in - accept the invite (if the logged in user is the invitee user)
    async function acceptInviteAndNavToOrgProjects(token: string) {
      const result = await acceptUserInvite({
        id: token,
      });

      if ("data" in result && result.data.acceptUserInvite?.organization_slug) {
        const slug = result.data.acceptUserInvite.organization_slug;
        navigate(urls.setOrganizationSlug(slug).homeRoute());
      }
    }

    if (token && auth.isLoggedIn) {
      acceptInviteAndNavToOrgProjects(token);
    }
  }, [auth.isLoggedIn]);

  useEffect(() => {
    // invitee user is registered but not logged in - redirect to login page, accept invite upon logging in
    if (
      (!auth.isLoggedIn || invitation?.user_registered) &&
      invitation?.organization_slug
    ) {
      const route = urls
        .setOrganizationSlug(invitation.organization_slug)
        .homeRoute();

      navigate({
        pathname: route.pathname,
        search: `?invite_id=${token}`,
      });
    }
  }, [Boolean(invitation?.user_registered)]);

  const hasInvitation = !isLoading && invitation;

  return (
    <AuthPageContainer>
      <LoadingOverlay isLoading={isLoading || auth.isLoading} />
      <AuthFormContainer title="Sign Up">
        {hasInvitation ? (
          <Text>Create an Enurgen account below.</Text>
        ) : (
          <Alert
            bg={"gray.100"}
            maxW="fit-content"
            borderRadius={"md"}
            p={2}
            fontSize="sm"
            my={2}
          >
            <Text>
              DUET is currently invite only.{" "}
              <Link href="https://enurgen.com/duet-designer-waitlist">
                Click here to join wait list.
              </Link>
            </Text>
          </Alert>
        )}

        <Box w="100%" maxW={"480px"}>
          <form
            onSubmit={form.handleSubmit(({ username, password }) => {
              auth.firebaseCreateUserWithEmailAndPassword(
                username,
                password,
                invitation?.id // invite_id
              );
              ampli.signUp({
                email: username,
              });
            })}
          >
            <Stack direction="column" spacing={5}>
              {hasInvitation && (
                <>
                  <FormField label="Email">
                    <Input
                      type="email"
                      placeholder="user@enurgen.com"
                      isDisabled
                      {...form.register("username")}
                    />
                  </FormField>

                  <FormField label="Password">
                    <PasswordInput
                      placeholder="Password"
                      isDisabled={!invitation}
                      {...form.register("password", { required: true })}
                    />
                  </FormField>
                  <FormField
                    label="Confirm Password"
                    error={errors.confirm?.message}
                    isInvalid={!!errors.confirm}
                  >
                    <PasswordInput
                      placeholder="Confirm Password"
                      isDisabled={!invitation}
                      {...form.register("confirm", {
                        required: true,
                        validate: async (confirm, { password }) => {
                          if (confirm !== password) {
                            return "Passwords do not match";
                          }
                        },
                      })}
                    />
                  </FormField>
                  {isSubmitted && isDirty && !auth.isLoading && !!auth.err && (
                    <AuthError err={auth.err} />
                  )}
                  <Button
                    type="submit"
                    variant={"primary"}
                    isLoading={auth.isLoading}
                    isDisabled={!invitation}
                  >
                    Sign Up
                  </Button>
                </>
              )}

              <HStack w="100%" justify="center">
                <Text textAlign={"center"}>Already have an account? </Text>
                <Link
                  as={RouterLink}
                  to={urls.loginRoute()}
                  fontWeight={"bold"}
                  textDecoration={"underline"}
                >
                  Log in
                </Link>
              </HStack>
            </Stack>
          </form>
        </Box>
      </AuthFormContainer>
    </AuthPageContainer>
  );
}
