import { Box, Center, Flex, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { TbCloudLock } from "react-icons/tb";
import { Link as RouterLink } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { AppBar } from "../AppBar";

interface NoAccessProps {
  resource: "project" | "organization";
  resourceName?: string;
}

// TODO: Add a link to request access if the user is in the allowed domain
export function NoAccess({ resource, resourceName }: NoAccessProps) {
  const urls = useAppUrls();
  return (
    <Box w="100vw" h="100vh">
      <Flex
        direction="column"
        w="100%"
        h="100%"
        position={"relative"}
        overflow="hidden"
      >
        <AppBar />
        <Center w="100%" h="100%" flexGrow={1}>
          <Stack>
            <Icon as={TbCloudLock} boxSize={10} />
            <Text
              as="h2"
              fontSize={"4xl"}
              fontFamily={"mono"}
              fontWeight={"black"}
            >
              Sorry, you don&apos;t have access.
            </Text>
            <Text>
              You don&apos;t have access to the <b>{resourceName}</b> {resource}
              . Contact the {resource} owner to request access.
            </Text>
            <Link
              as={RouterLink}
              to={urls.homeRoute()}
              textDecoration={"underline"}
            >
              <>Go Home &rarr;</>
            </Link>
          </Stack>
        </Center>
      </Flex>
    </Box>
  );
}
