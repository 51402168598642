import { useContext } from "react";
import { UserContext } from "../auth/AuthRoute";

export function useActiveUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useActiveUser must be used within UserContext");
  }

  return {
    user: context.user,
    refetch: context.refetch,
  };
}
