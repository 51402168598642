import { FirebaseError } from "firebase/app";
import {
  AuthError,
  connectAuthEmulator,
  User as FirebaseUser,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorAssertion,
  MultiFactorInfo,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorAssertion,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  TotpMultiFactorGenerator,
  TotpMultiFactorInfo,
  TotpSecret,
} from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
  useSendPasswordResetEmail,
  useSignInWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import { app } from "~/firebase";
import {
  useFirebaseAppLoginMutation,
  useFirebaseAppRegisterMutation,
} from "~/gql/generated";

export function useFirebaseAuth() {
  const firebaseAuth = useMemo(() => {
    const auth = getAuth(app);
    if (
      import.meta.env.DEV &&
      import.meta.env.VITE_FIREBASE_AUTH_EMULATOR &&
      // we don't want to connect to the emulator if we're proxy to the prod api
      !import.meta.env.VITE_API_PROXY
    ) {
      connectAuthEmulator(auth, import.meta.env.VITE_FIREBASE_AUTH_EMULATOR, {
        disableWarnings: true,
      });
    }
    return auth;
  }, []);
  const [firebaseUser, firebaseAuthStateLoading] = useAuthState(firebaseAuth);
  const [
    signInWithEmailAndPassword,
    ,
    firebaseSignInWithEmailAndPasswordLoading,
    firebaseSignInWithEmailAndPasswordError,
  ] = useSignInWithEmailAndPassword(firebaseAuth);

  const [
    createUserWithEmailAndPassword,
    ,
    firebaseCreateUserWithEmailAndPasswordLoading,
    firebaseCreateUserWithEmailAndPasswordError,
  ] = useCreateUserWithEmailAndPassword(firebaseAuth);

  const [firebaseSendPasswordResetEmail, firebasePasswordResetEmailIsLoading] =
    useSendPasswordResetEmail(firebaseAuth);

  const [firebaseSendEmailVerification] =
    useSendEmailVerification(firebaseAuth);

  const [firebaseAppLoginMutation, firebaseAppLoginMutationResponse] =
    useFirebaseAppLoginMutation();

  const [firebaseAppRegisterMutation, firebaseAppRegisterMutationResponse] =
    useFirebaseAppRegisterMutation();

  const firebaseSignInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    const credential = await signInWithEmailAndPassword(email, password);

    if (!credential) {
      return;
    }

    const token = await credential.user.getIdToken();
    await firebaseAppLoginMutation({ token, credential });
  };

  const firebaseCreateUserWithEmailAndPassword = async (
    email: string,
    password: string,
    invite_id?: string
  ) => {
    const credential = await createUserWithEmailAndPassword(email, password);

    if (!credential) {
      return;
    }

    const token = await credential.user.getIdToken();
    await firebaseAppRegisterMutation({ token, credential, invite_id });

    await firebaseSendEmailVerification();
  };

  const [firebaseError, setFirebaseError] = useState<
    FirebaseError | AuthError | null
  >(null);

  useEffect(() => {
    if (firebaseSignInWithEmailAndPasswordError) {
      setFirebaseError(firebaseSignInWithEmailAndPasswordError);
    }
  }, [firebaseSignInWithEmailAndPasswordError]);

  useEffect(() => {
    if (firebaseCreateUserWithEmailAndPasswordError) {
      setFirebaseError(firebaseCreateUserWithEmailAndPasswordError);
    }
  }, [firebaseCreateUserWithEmailAndPasswordError]);

  return {
    firebaseAuth,
    firebaseUser,
    isLoading:
      firebaseAuthStateLoading ||
      firebaseSignInWithEmailAndPasswordLoading ||
      firebasePasswordResetEmailIsLoading ||
      firebaseCreateUserWithEmailAndPasswordLoading,
    error: firebaseError,
    // exposing this because we don't remount auth context so the hooks don't reset error state
    // maybe we should split up auth context into multiple contexts, 1 that deals with register/login and 1 that deals with authenticated state?
    clearFirebaseError: () => setFirebaseError(null),
    firebaseAuthStateLoading,
    firebasePasswordResetEmailIsLoading,
    firebaseCreateUserWithEmailAndPasswordLoading,
    firebaseCreateUserWithEmailAndPasswordError,
    firebaseSignInWithEmailAndPasswordLoading,
    firebaseSignInWithEmailAndPasswordError,
    firebaseAppLoginMutation,
    firebaseAppLoginMutationResponse,
    firebaseAppRegisterMutationResponse,
    firebaseSignInWithEmailAndPassword,
    firebaseSendPasswordResetEmail,
    firebaseCreateUserWithEmailAndPassword,
    firebaseSendEmailVerification,
  };
}

export function useFirebaseMFA(
  firebaseAuthHookValue: ReturnType<typeof useFirebaseAuth>
) {
  const { firebaseAuth, error, firebaseAppLoginMutation, clearFirebaseError } =
    firebaseAuthHookValue;
  const [err, setErr] = useState<FirebaseError | null>(null);

  const mfaLoginResolver = useMemo(() => {
    if (error?.code !== "auth/multi-factor-auth-required") {
      return null;
    }

    return getMultiFactorResolver(
      firebaseAuth,
      // @ts-expect-error
      error
    );
  }, [firebaseAuth, error]);

  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null);

  function handleFirebaseError(e: any) {
    if (e instanceof FirebaseError) {
      setErr(e);
    }
    console.error(e);
  }

  function initRecaptchaVerifier(el: string | HTMLElement) {
    if (recaptchaVerifier) {
      try {
        recaptchaVerifier.clear();
      } catch (e) {
        console.error(e);
        setRecaptchaVerifier(null);
      }
    }
    const verifier = new RecaptchaVerifier(firebaseAuth, el, {
      size: "invisible",
    });
    setRecaptchaVerifier(verifier);
    return verifier;
  }

  function initMFASession() {
    if (!firebaseAuth.currentUser) {
      throw new Error("No user found");
    }
    return multiFactor(firebaseAuth.currentUser).getSession();
  }

  async function verifyPhoneNumber(
    phoneInfoOptions: PhoneInfoOptions,
    verifier: RecaptchaVerifier
  ) {
    try {
      const phoneAuthProvider = new PhoneAuthProvider(firebaseAuth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        verifier
      );
      return verificationId;
    } catch (e) {
      handleFirebaseError(e);
      verifier.clear();
      setRecaptchaVerifier(null);
    }
  }

  async function verifySMSCode(verificationId: string, code: string) {
    try {
      const credential = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(credential);

      return multiFactorAssertion;
    } catch (e) {
      handleFirebaseError(e);
    }
  }

  async function verifyTOTPCodeForEnrollment(
    verificationId: string,
    totpSecret: TotpSecret
  ) {
    try {
      const multiFactorAssertion =
        TotpMultiFactorGenerator.assertionForEnrollment(
          totpSecret,
          verificationId
        );

      return multiFactorAssertion;
    } catch (e) {
      handleFirebaseError(e);
    }
  }

  async function verifyTOTPCodeForLogin(
    verificationId: string,
    totpInfo: TotpMultiFactorInfo
  ) {
    try {
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
        totpInfo.uid,
        verificationId
      );

      return multiFactorAssertion;
    } catch (e) {
      handleFirebaseError(e);
    }
  }

  async function generateTOTPSecret() {
    try {
      const mfaSession = await initMFASession();

      return await TotpMultiFactorGenerator.generateSecret(mfaSession);
    } catch (e) {
      handleFirebaseError(e);
    }
  }

  async function verifyMFALogin(
    multiFactorAssertion: PhoneMultiFactorAssertion,
    invite_id?: string | null
  ) {
    if (!mfaLoginResolver) {
      throw new Error("No MFA resolver found");
    }

    const userCredential =
      await mfaLoginResolver.resolveSignIn(multiFactorAssertion);

    const token = await userCredential.user.getIdToken();
    await firebaseAppLoginMutation({
      token,
      credential: userCredential,
      invite_id,
    });
    clearFirebaseError();
  }

  async function enrollMFA(multiFactorAssertion: MultiFactorAssertion) {
    if (!firebaseAuth.currentUser) {
      throw new Error("No user found");
    }
    try {
      return await multiFactor(firebaseAuth.currentUser).enroll(
        multiFactorAssertion,
        multiFactorAssertion.factorId
      );
    } catch (e) {
      handleFirebaseError(e);
    }
  }

  async function unenrollMFA(factor: MultiFactorInfo) {
    if (!firebaseAuth.currentUser) {
      throw new Error("No user found");
    }
    try {
      return await multiFactor(firebaseAuth.currentUser).unenroll(factor);
    } catch (e) {
      handleFirebaseError(e);
      throw e;
    }
  }

  const firebaseRequiresRecentLogin =
    err?.code === "auth/requires-recent-login" || !firebaseAuth.currentUser;

  return {
    isMFALogin: Boolean(mfaLoginResolver),
    mfaLoginResolver,
    err,
    firebaseRequiresRecentLogin,
    clearFirebaseMFAError: () => setErr(null),
    initMFASession,
    initRecaptchaVerifier,
    verifyPhoneNumber,
    verifySMSCode,
    verifyTOTPCodeForEnrollment,
    verifyTOTPCodeForLogin,
    generateTOTPSecret,
    verifyMFALogin,
    enrollMFA,
    unenrollMFA,
  };
}

export interface FirebaseIdentityProviderPayload extends FirebaseUser {
  // not sure why this isn't in the firebase types
  multiFactor?: {
    enrolledFactors: MultiFactorInfo[];
  };
}

export async function devFetchAndCopyVerificationCode() {
  interface VerificationCodesResponse {
    verificationCodes: Array<{
      code: string;
      phoneNumber: string;
      sessionInfo: string;
    }>;
  }

  const { verificationCodes } = await fetch(
    "http://localhost:9099/emulator/v1/projects/enurgen-duet/verificationCodes",
    {}
  ).then((res) => res.json() as Promise<VerificationCodesResponse>);
  const code = verificationCodes[verificationCodes.length - 1];

  if (!code) return;
  await navigator.clipboard.writeText(code.code);
  return code.code;
}
