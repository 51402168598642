import { Center } from "@chakra-ui/react";
import { createContext, useEffect, useRef } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getRefererAndInviteId, useAppUrls } from "~/appUrls";
import { LoadingOverlay } from "~/components/LoadingOverlay/LoadingOverlay";
import { UserMeQuery, useUserMeQuery } from "~/gql/generated";
import { useFeatureFlags } from "../featureFlag/hooks";
import { OrganizationProvider } from "../organization/OrganizationProvider";
import { useAuth } from "./AuthContext";

export const UserContext = createContext<{
  user: UserMeQuery["me"];
  refetch: () => Promise<void>;
}>({} as any);

export function AuthRoute({ component }: { component?: React.ReactNode }) {
  const { isLoggedIn, authTokenParsed, logout } = useAuth();
  const { isGaOpenRegistrationEnabled } = useFeatureFlags([
    "ga-open-registration",
  ]);
  const urls = useAppUrls();
  const location = useLocation();

  const { isLoading, error, data, refetch } = useUserMeQuery(
    {},
    {
      skip: !isLoggedIn,
    }
  );

  useEffect(() => {
    if (error?.message === "Unauthorized") {
      logout();
    }
  }, [error?.message]);

  const user = data?.me;

  const wasLoggedIn = useRef(false);

  if (isLoggedIn) {
    wasLoggedIn.current = true;
  }

  if (!isLoggedIn) {
    if (wasLoggedIn.current) {
      // if we were previously logged in and now we're not, we should redirect to the login page and ignore the current location
      // if the referer was set intentionally we will include it
      const referer = new URLSearchParams(location.search).get("referer");
      return <Navigate to={urls.loginRoute(referer || undefined)} />;
    }
    // if we're visiting a link directily and not logged in we should redirect to the login page
    // we are passing the current location as the referer
    const { referer, invite_id } = getRefererAndInviteId(location);

    return <Navigate to={urls.loginRoute(referer, invite_id)} />;
  }

  if (isLoading || !user) {
    return (
      <Center h="100vh">
        <LoadingOverlay isLoading />
      </Center>
    );
  }

  const pageContent = (
    <UserContext.Provider
      value={{
        user,
        refetch: async () => {
          await refetch();
        },
      }}
    >
      <OrganizationProvider>
        {component}
        <Outlet />
      </OrganizationProvider>
    </UserContext.Provider>
  );

  if (!isGaOpenRegistrationEnabled) {
    return pageContent;
  }

  // all the following checks are for the open registration onboarding flow
  const shouldDoEmailVerification =
    authTokenParsed?.intercept === "email_verification";

  if (shouldDoEmailVerification) {
    if (location.pathname === urls.emailVerification().pathname) {
      return pageContent;
    }

    return <Navigate to={urls.emailVerification()} />;
  }

  const shouldDoOnboardingIntake = !user?.has_lead_intake_form;

  if (shouldDoOnboardingIntake) {
    if (location.pathname === urls.onboardingIntakeForm().pathname) {
      return pageContent;
    }
    return <Navigate to={urls.onboardingIntakeForm().toString()} />;
  }

  return pageContent;
}
