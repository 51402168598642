import { getLineIntersections, medianAngle } from "@duet/shared/geo";
import {
  MechanicalShapeEnum,
  PanelOrientationEnum,
} from "@duet/shared/pvComponents/projectParameters";
import {
  Feature,
  FeatureCollection,
  LineString,
  Polygon,
  bearing,
  center,
  degreesToRadians,
  destination,
  distance,
  envelope,
  featureCollection,
  lineString,
  nearestPoint,
} from "@turf/turf";
import { map, meanBy, pull, round } from "lodash";

import { PvTableUpdateInput } from "~/gql/generated";

export interface FrameCollectedMetadata {
  groupId: string;
  name: string;
  num_strings: number;
  num_cols: number;
  num_tiers: number;
  orientation_str: string;
}

export interface FramePopulation {
  id: string;
  name: string;
}

export interface FrameDimensions {
  group: string;
  short: number;
  long: number;
}

export interface FrameModuleDimensions {
  module_width: number;
  module_height: number;
  module_x_spacing: number;
  module_y_spacing: number;
}

export interface FrameTracking {
  tracker_type: string;
  min_phi: number;
  max_phi: number;
  min_theta?: number;
  max_theta?: number;
}

export interface FrameTableMetadata {
  num_strings: number;
  num_cols: number;
  num_tiers: number;
  orientation_str: PanelOrientationEnum;
}

export interface FrameProperties {
  population?: FramePopulation;
  azimuth?: number;
  fixedTilt?: number;
  frameDimensions: FrameDimensions;
  moduleDimensions?: FrameModuleDimensions;
  tracking?: FrameTracking;
  tableMetadata: FrameTableMetadata;
}

export type FrameFeature<P = FrameProperties> = Feature<Polygon, P>;

export function makeTableParamsForFramePopulation(
  framePopulation: FrameCollectedMetadata,
  frames: FrameFeature[]
) {
  const sampleFrame = frames[0];
  const azimuths = map(frames, "properties.azimuth");
  const { frameDimensions, moduleDimensions, tracking } =
    sampleFrame.properties;

  const numTiers = framePopulation.num_tiers;
  const frameDepth = 0.03;
  const purlinLength =
    numTiers === 1 || !moduleDimensions
      ? 0.3
      : numTiers * moduleDimensions.module_height +
        (numTiers - 1) * moduleDimensions.module_y_spacing;
  const purlinDimensions = [0.03, purlinLength, 0.1];
  const torqueTubeRectDimensions = [0.2, 0.2];

  const parameters: PvTableUpdateInput = {
    azimuth: round(medianAngle(azimuths) ?? 0, 2),

    num_cols: framePopulation.num_cols,
    num_tiers: framePopulation.num_tiers,
    orientation_str: framePopulation.orientation_str,

    table_gap: 1,
    torque_tube_enabled: true,
    torque_tube_shape: MechanicalShapeEnum.Rect,
    torque_tube_rect_dimensions: torqueTubeRectDimensions,

    in_plane_racking_enabled: true,
    in_plane_racking_dimensions: purlinDimensions,
  };

  if (moduleDimensions) {
    parameters.column_gap = moduleDimensions.module_x_spacing;
    parameters.tier_gap = moduleDimensions.module_y_spacing;
  }

  if (tracking) {
    parameters.backtracking_enabled = true;
    parameters.tracking_type = 1;

    parameters.stow_angle = 0;
    parameters.track_angle_bounds = [tracking.min_phi, tracking.max_phi];

    parameters.rotation_radius =
      torqueTubeRectDimensions[1] / 2 + purlinDimensions[2] + frameDepth;

    parameters.torque_tube_height =
      1 +
      Math.sin(degreesToRadians(tracking.max_phi)) * frameDimensions.short -
      Math.cos(degreesToRadians(tracking.max_phi)) * parameters.rotation_radius;
  } else {
    parameters.ground_clearance = 1;

    const averageTilt = meanBy(frames, "properties.fixedTilt");
    parameters.tilt = round(averageTilt, 2);
    parameters.tracking_type = 0;
  }

  const tableParameters: PvTableUpdateInput = {
    ...parameters,
    default_state_fields: parameters,
  };

  return tableParameters;
}

export function generatePopulationName(existing: string[]) {
  // TODO: Handle 27+ instances spreadsheet-style?
  // e.g. A, ..., Z, AA, AB, ...
  const suffix = String.fromCharCode(65 + existing.length);
  return `Population ${suffix}`;
}

export function makePopulationWithFallback(properties: FrameProperties) {
  // TODO: deprecate frameDimensions.group
  const { population, frameDimensions } = properties;
  return {
    id: population?.id ?? frameDimensions.group,
    name: population?.name ?? frameDimensions.group,
  };
}

export interface FramePopulationStats {
  id: string;
  name: string;
  dimensions: {
    short: number;
    long: number;
  };
  count: number;
}

export function getFramePopulationStats(frames: FrameFeature[]) {
  return frames.reduce<Record<string, FramePopulationStats>>(
    (populations, frame) => {
      const { id, name } = makePopulationWithFallback(frame.properties);

      if (!populations[id]) {
        populations[id] = {
          id,
          name,
          dimensions: frame.properties.frameDimensions,
          count: 0,
        };
      }

      populations[id].count++;
      return populations;
    },
    {}
  );
}

export function renameFramePopulation(
  geojson: FeatureCollection<Polygon, FrameProperties>,
  id: string,
  name: string
) {
  const features = geojson.features.map((frame) => {
    const population = makePopulationWithFallback(frame.properties);
    if (population.id === id) {
      population.name = name;
    }
    return {
      ...frame,
      properties: {
        ...frame.properties,
        population,
      },
    };
  });

  return featureCollection(features);
}

export function getBearing(polygon: Polygon) {
  const [[p0, p1, p2]] = polygon.coordinates;
  const [first, second] = [distance(p0, p1), distance(p1, p2)];

  if (first < second) {
    return bearing(p0, p1);
  }

  return bearing(p1, p2);
}

/**
 * Get a LineString segment to span a collection of features and test for intersections
 * @param collection
 * @param bearing
 * @returns
 */
export function makeIntersectionLine(
  collection: FeatureCollection,
  bearing: number
) {
  const bbox = envelope(collection);
  const centers = collection.features.map((feature) => center(feature));
  const origin = nearestPoint(center(bbox), featureCollection(centers));

  // In the worst case the frame nearest to the bounding box center may
  // still be somewhat far away so we'll cast maximum distance of the
  // bounding box.
  const castDistance = distance(
    bbox.geometry.coordinates[0][0],
    bbox.geometry.coordinates[0][1],
    { units: "meters" }
  );

  const start = destination(
    origin.geometry.coordinates,
    castDistance,
    bearing,
    { units: "meters" }
  );

  const end = destination(
    origin.geometry.coordinates,
    castDistance,
    bearing + 180,
    { units: "meters" }
  );

  return lineString([start.geometry.coordinates, end.geometry.coordinates]);
}

/**
 * Determine the pitch between rows for a collection of frames
 *
 * This assumes that all frames have the same azimuth and that the frames
 * between rows are mostly aligned.
 *
 * Iteratively casts lines through frames along the azimuth, measuring the
 * the distance along that line between each intersected frame. The row pitch
 * is the minimum measured distance.
 *
 * @param frames
 * @returns {number}
 */
export function getRowPitch(frames: FrameFeature[]): number | undefined {
  // Assuming all frames have the same azimuth
  const bearing = getBearing(frames[0].geometry);
  const toTest = getFramesAsLines(frames);

  // TODO: What happens if there's no frame alignment between rows?
  const pitches = [];
  while (toTest.length > 0) {
    const line = makeIntersectionLine(featureCollection(toTest), bearing);
    const intersections = getLineIntersections(toTest, line.geometry).sort(
      (a, b) => a.t - b.t
    );

    pull(toTest, ...map(intersections, "feature"));
    for (let i = 0; i < intersections.length - 1; i++) {
      pitches.push(Math.abs(intersections[i + 1].t - intersections[i].t));
    }
  }

  return pitches.length > 0 ? round(Math.min(...pitches), 2) : undefined;
}

/**
 * Represent frames as line segments perpendicular to their azimuth
 */
function getFramesAsLines(frames: FrameFeature[]): Array<Feature<LineString>> {
  return frames.map((frame) => {
    const bearing = getBearing(frame.geometry);
    const length = frame.properties.frameDimensions.long;
    const mid = center(frame).geometry;

    return lineString(
      [
        destination(mid, length / 2, bearing + 90, { units: "meters" }).geometry
          .coordinates,
        destination(mid, length / 2, bearing - 90, { units: "meters" }).geometry
          .coordinates,
      ],
      frame.properties,
      { id: frame.id }
    );
  });
}
