import { isNil, isNumber, isString } from "lodash";
import { DeepPartial } from "react-hook-form";
import { findMedian } from "../utils";
import {
  CellType,
  MechanicalShapeEnum,
  PanelOrientationEnum,
} from "./projectParameters";
import {
  DeepNullish,
  InputFieldMetadata,
  InverterFormValue,
  PanelFormValue,
  TableFormValue,
  Unit,
} from "./types";

export function isEmptyNumberInput(
  value: unknown
): value is undefined | null | "" {
  return ([undefined, null, "", NaN] as any[]).includes(value);
}

export function isEmptyStringInput(value: unknown) {
  return ([undefined, null, ""] as any[]).includes(value);
}

export function isIncompleteArrayInput(value: unknown, expectedLength: number) {
  if (!Array.isArray(value)) {
    return true;
  }
  return value
    .slice(0, expectedLength)
    .some((elem) => isEmptyNumberInput(elem));
}

export function convertUnitIfNeeded(
  fromUnit: Unit | undefined,
  toUnit: Unit | undefined,
  value: undefined
): undefined;
export function convertUnitIfNeeded(
  fromUnit: Unit | undefined,
  toUnit: Unit | undefined,
  value: number
): number;
export function convertUnitIfNeeded(
  fromUnit: Unit | undefined,
  toUnit: Unit | undefined,
  value: number | undefined
): number | undefined;
export function convertUnitIfNeeded(
  fromUnit: Unit | undefined,
  toUnit: Unit | undefined,
  value: number | undefined
): number | undefined {
  if (isNil(value)) {
    return value;
  }
  if (fromUnit === toUnit) {
    return value;
  }
  // TODO: if more cases come up, use better abstraction or a library
  if (fromUnit === "m" && toUnit === "mm") {
    return 1e3 * value;
  }
  if (fromUnit === "mm" && toUnit === "m") {
    return value / 1e3;
  }

  if (fromUnit === "kW" && toUnit === "W") {
    return 1e3 * value;
  }
  if (fromUnit === "W" && toUnit === "kW") {
    return value / 1e3;
  }

  if (fromUnit === "MW" && toUnit === "W") {
    return 1e6 * value;
  }
  if (fromUnit === "W" && toUnit === "MW") {
    return value / 1e6;
  }

  if (fromUnit === "MW" && toUnit === "kW") {
    return 1e3 * value;
  }
  if (fromUnit === "kW" && toUnit === "MW") {
    return value / 1e3;
  }

  if (!fromUnit && toUnit === "%") {
    return 1e2 * value;
  }
  if (fromUnit === "%" && !toUnit) {
    return value / 1e2;
  }

  return value;
}

export function resolveSystemUnitAndDisplayUnit(
  fieldMetadata: InputFieldMetadata<any> | undefined,
  unitOverride?: Unit,
  displayUnitOverride?: Unit
) {
  let systemUnit = fieldMetadata?.unit;
  let displayUnit =
    fieldMetadata && "displayUnit" in fieldMetadata
      ? fieldMetadata.displayUnit
      : undefined;
  if (unitOverride) {
    systemUnit = unitOverride;
  }
  if (displayUnitOverride) {
    displayUnit = displayUnitOverride;
  }
  if (!displayUnit) {
    displayUnit = systemUnit;
  }

  return { systemUnit, displayUnit };
}

export function findReferenceCecCurve(
  cec_efficiency_curves: DeepNullish<InverterFormValue["cec_efficiency_curves"]>
) {
  const inputVoltages = (cec_efficiency_curves ?? []).flatMap((curve) => {
    return curve?.input_voltage ?? [];
  });

  if (inputVoltages.length % 2 === 1) {
    const inputVoltage = findMedian(inputVoltages);
    if (inputVoltage) {
      return (
        (cec_efficiency_curves ?? []).find(
          (curve) => curve?.input_voltage === inputVoltage
        ) ?? undefined
      );
    }
  }
  return undefined;
}

const cellSizesDescendingOrder: number[] = [
  0.21, 0.182, 0.166, 0.16675, 0.165, 0.1617, 0.15875, 0.15675, 0.156,
];

export function guessCellDimensionFromCommonCellSizes({
  panelHeight,
  panelWidth,
  numCellsLength,
  numCellsWidth,
  cell_type,
}: {
  panelHeight: number;
  panelWidth: number;
  numCellsLength: number;
  numCellsWidth: number;
  cell_type: CellType | undefined;
}) {
  // Try to find the largest cell size that fits the panel
  for (const fullCellSize of cellSizesDescendingOrder) {
    const cell_length =
      cell_type === CellType.Half ? fullCellSize * 0.5 : fullCellSize;
    const cell_width = fullCellSize;
    if (
      cell_length * numCellsLength <= panelHeight &&
      cell_width * numCellsWidth <= panelWidth
    ) {
      return {
        cell_length,
        cell_width,
      };
    }
  }
  return undefined;
}

export function calculatePanelEdgeSpacingOneSide({
  numCells,
  cellLength,
  panelLength,
  cellSpacing,
  centerSpacing,
}: {
  numCells: number;
  cellLength: number;
  panelLength: number;
  cellSpacing: number;
  centerSpacing?: number;
}) {
  if (isNumber(centerSpacing)) {
    return (
      (panelLength -
        numCells * cellLength -
        (numCells - 2) * cellSpacing -
        centerSpacing) /
      2
    );
  } else {
    return (
      (panelLength - numCells * cellLength - (numCells - 1) * cellSpacing) / 2
    );
  }
}

export function calculateCellSpacingOneSide({
  numCells,
  cellLength,
  panelLength,
  panelEdgeSpacing,
  centerSpacing,
}: {
  numCells: number;
  cellLength: number;
  panelLength: number;
  panelEdgeSpacing: number;
  centerSpacing?: number;
}) {
  if (isNumber(centerSpacing)) {
    return (
      (panelLength -
        numCells * cellLength -
        2 * panelEdgeSpacing -
        centerSpacing) /
      (numCells - 2)
    );
  } else {
    return (
      (panelLength - numCells * cellLength - 2 * panelEdgeSpacing) /
      (numCells - 1)
    );
  }
}

export function calculateHalfCutCenterSpace({
  cellLength,
  numCells,
  panelLength,
  panelEdgeSpacing,
  cellSpacing,
}: {
  cellLength: number;
  numCells: number;
  panelLength: number;
  panelEdgeSpacing: number;
  cellSpacing: number;
}) {
  return (
    panelLength -
    numCells * cellLength -
    (numCells - 2) * cellSpacing -
    2 * panelEdgeSpacing
  );
}

export function validateCellDimension({
  numCellsLength,
  numCellsWidth,
  cell_length,
  cell_width,
  panelHeight,
  panelWidth,
}: {
  numCellsLength: number;
  numCellsWidth: number;
  cell_length: number;
  cell_width: number;
  panelHeight: number;
  panelWidth: number;
}) {
  return (
    numCellsLength * cell_length <= panelHeight &&
    numCellsWidth * cell_width <= panelWidth
  );
}

export function calculateDefaultRotationRadius({
  frameDepth,
  purlinDepth,
  torque_tube_shape,
  torque_tube_rect_dimensions,
  torque_tube_radius,
}: {
  frameDepth: number;
  purlinDepth: number;
} & DeepPartial<
  Pick<
    TableFormValue,
    "torque_tube_radius" | "torque_tube_rect_dimensions" | "torque_tube_shape"
  >
>) {
  if (
    torque_tube_shape === MechanicalShapeEnum.Rect &&
    torque_tube_rect_dimensions &&
    isNumber(torque_tube_rect_dimensions[0])
  ) {
    return 0.5 * torque_tube_rect_dimensions[0] + purlinDepth + frameDepth;
  } else if (
    torque_tube_shape === MechanicalShapeEnum.Round &&
    isNumber(torque_tube_radius)
  ) {
    return torque_tube_radius + purlinDepth + frameDepth;
  }
  return undefined;
}

function calculatePanelSideLength({
  cellLength,
  numCells,
  cellSpacing,
  panel_edge_b,
  panel_edge_t,
  centerSpacing,
}: {
  cellLength: number;
  numCells: number;
  cellSpacing: number;
  centerSpacing?: number;
  panel_edge_t: number;
  panel_edge_b: number;
}) {
  if (isNumber(centerSpacing)) {
    return (
      cellLength * numCells +
      cellSpacing * (numCells - 2) +
      centerSpacing +
      panel_edge_t +
      panel_edge_b
    );
  } else {
    return (
      cellLength * numCells +
      cellSpacing * (numCells - 1) +
      panel_edge_t +
      panel_edge_b
    );
  }
}

export function calculateCollectorWidth({
  num_tiers,
  panelHeight,
  tier_gap,
}: {
  num_tiers: number;
  panelHeight: number;
  tier_gap: number | undefined;
}) {
  return num_tiers * panelHeight + (tier_gap ?? 0) * (num_tiers - 1);
}

/**
 * Intermediate variables:
 * Wc = collector width
 * Hc = vertical half-height of the collector
 * Hr = vertical height of the radius of rotation
 * B = largest magnitude tilt angle from tilt angle bounds
 * G = ground clearance for SAT (ground to radius of rotation).
 * R = radius of rotation
 */
export function calculateMinTorqueTubeHeight(
  pv_panel: PanelFormValue,
  pv_table: TableFormValue
): number | undefined {
  const {
    orientation_str,
    num_tiers,
    tier_gap,
    rotation_radius,
    track_angle_bounds,
  } = pv_table;

  if (
    isNil(orientation_str) ||
    isNil(num_tiers) ||
    !track_angle_bounds ||
    isNil(track_angle_bounds[0]) ||
    isNil(track_angle_bounds[1])
  ) {
    return undefined;
  }

  const panelHeight = calculateModuleDimensionsFromPanelComponent(
    pv_panel,
    orientation_str
  ).height;

  if (!panelHeight) {
    return undefined;
  }

  const Wc = calculateCollectorWidth({
    num_tiers,
    panelHeight,
    tier_gap,
  });
  const R = rotation_radius ?? 0;
  const B = Math.max(
    Math.abs(track_angle_bounds[0]),
    Math.abs(track_angle_bounds[1])
  );

  const Hc = (Wc / 2) * Math.sin((B * Math.PI) / 180);
  const Hr = R * Math.cos((B * Math.PI) / 180);
  return Hc - Hr;
}

export function calculateModuleDimensionsFromPanelComponent(
  pvPanel: PanelFormValue,
  panelOrientation: PanelOrientationEnum = PanelOrientationEnum.PORTRAIT
) {
  const {
    cell_length,
    cell_width,
    cell_spacing_lr,
    cell_spacing_tb,
    panel_edge_lr,
    panel_edge_t,
    panel_edge_b,
    cells,
    cell_type,
  } = pvPanel;

  const [rows, columns] = cells ?? [];

  const half_cut_center_space =
    cell_type === "half" ? pvPanel.half_cut_center_space : undefined;

  let lengthYPortrait: number | undefined;
  if (
    isString(cell_type) &&
    isNumber(cell_length) &&
    isNumber(cell_spacing_tb) &&
    isNumber(panel_edge_t) &&
    isNumber(panel_edge_b) &&
    isNumber(rows) &&
    (isNumber(half_cut_center_space) || cell_type === "full")
  ) {
    lengthYPortrait = calculatePanelSideLength({
      cellLength: cell_length,
      numCells: rows,
      cellSpacing: cell_spacing_tb,
      panel_edge_t: panel_edge_t,
      panel_edge_b: panel_edge_b,
      centerSpacing: half_cut_center_space,
    });
  }

  let lengthXPortrait: number | undefined;
  if (
    isNumber(cell_width) &&
    isNumber(cell_spacing_lr) &&
    isNumber(panel_edge_lr) &&
    isNumber(columns)
  ) {
    lengthXPortrait = calculatePanelSideLength({
      cellLength: cell_width,
      numCells: columns,
      cellSpacing: cell_spacing_lr,
      panel_edge_t: panel_edge_lr,
      panel_edge_b: panel_edge_lr,
      centerSpacing: undefined,
    });
  }

  if (panelOrientation === PanelOrientationEnum.LANDSCAPE) {
    return {
      height: lengthXPortrait,
      width: lengthYPortrait,
    };
  } else {
    return {
      height: lengthYPortrait,
      width: lengthXPortrait,
    };
  }
}

export function calculateBlockDimensionsFromPanelAndTable(
  pvPanel: PanelFormValue,
  pvTable: TableFormValue
) {
  if (!pvPanel || !pvTable) {
    return null;
  }

  const { width: panelBoxWidth, height: panelBoxHeight } =
    calculateModuleDimensionsFromPanelComponent(
      pvPanel,
      pvTable.orientation_str
    );

  if (!panelBoxWidth || !panelBoxHeight) {
    return null;
  }

  if (
    [
      "azimuth",
      "num_cols",
      "num_tiers",
      "row_spacing",
      "table_gap",
      "column_gap",
    ].some((key) => isNil(pvTable[key as keyof TableFormValue]))
  ) {
    return null;
  }

  const totalTierGap = (pvTable.tier_gap ?? 0) * (pvTable.num_tiers! - 1);
  const tableHeight = panelBoxHeight * pvTable.num_tiers! + totalTierGap;

  const totalColumnGap = pvTable.column_gap! * (pvTable.num_cols! - 1);
  const tableWidth = panelBoxWidth * pvTable.num_cols! + totalColumnGap;

  const bboxDimensions = {
    width: tableWidth + pvTable.table_gap!,
    height: pvTable.row_spacing!,
  };

  return {
    tableHeight,
    tableWidth,
    bboxDimensions,
    tableGap: pvTable.table_gap!,
    rowSpacing: pvTable.row_spacing!,
    azimuth: pvTable.azimuth!,
  };
}
